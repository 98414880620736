import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
// components
import Btn from '@/components/Button/Btn.vue';
import FormWrap from '@/components/Elements/FormWrap.vue';
import PublicPageTitle from '@/components/Elements/PublicPageTitle.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import PasswordInput from '@/components/FormInputs/PasswordInput/PasswordInput.vue';
let ForgotPassword = class ForgotPassword extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            name: '',
            email: '',
            password: '',
            token: '',
        };
        this.errors = {
            name: [],
            email: [],
            password: [],
            token: [],
        };
        this.confirm_password = '';
        this.validPassword = false;
    }
    onPasswordConfirmChange() {
        this.validPassword = this.form.password === this.confirm_password;
    }
    mounted() { }
    async requestToken() {
        try {
            await this.$store.dispatch('auth/requestResetPasswordToken', this.form.email);
            await this.$router.push({ path: 'forgot/verify', query: { email: this.form.email } });
        }
        catch (e) {
            this.errors = e.data && e.data.messages ? e.data.messages : [];
        }
    }
};
__decorate([
    Watch('confirm_password')
], ForgotPassword.prototype, "onPasswordConfirmChange", null);
ForgotPassword = __decorate([
    Component({
        components: { Btn, FormWrap, PublicPageTitle, TextInput, PasswordInput },
        metaInfo() {
            return {
                title: 'Africa Geographic | Forgot',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic Forgot',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/forgot` }],
            };
        },
    })
], ForgotPassword);
export default ForgotPassword;
